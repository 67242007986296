import { createAsync } from '@solidjs/router';
import { Show, Suspense } from 'solid-js';
import { AccessProductsCtx, getAccessProducts } from '../providers/card';
import type { RouteDefinition, RouteSectionProps } from '@solidjs/router';

export default function CardsPageLayout(props: RouteSectionProps) {
	const data = createAsync(() => getAccessProducts({}), { deferStream: true });

	return (
		<Suspense>
			<Show when={data()?.products}>
				{(products) => <AccessProductsCtx.Provider value={products}>{props.children}</AccessProductsCtx.Provider>}
			</Show>
		</Suspense>
	);
}

export const route = {
	preload: async () => {
		return getAccessProducts({});
	},
} satisfies RouteDefinition;
